<template>
    <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        
        <template v-if="userDetailsLoading">
            <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </template>

        <!--begin::no user data available -->
        <template v-if="!userDetailsLoading && !user">
            <p class="text-center">{{ $t('commons.no_data_available') }}</p>
        </template>

        <!--begin::user -->
        <template v-if="user">
            <Breadcrumb :datas="{ title: '' }" v-if="userDetailsLoading" />
            <Breadcrumb :datas="breadcrumb_data" v-else />
            <!--begin::Entry-->
            <div class="d-flex flex-column-fluid">
                <!--begin::Container-->
                <div class="container-fluid">

                    <!--begin::sticky -->
                    <div class="row" data-sticky-container="">
                        <!--begin::aside -->
                        <div class="col-lg-4 col-xl-3">
                            <div class="card card-custom sticky" data-sticky="true" data-margin-top="140px" data-sticky-for="1023" data-sticky-class="sticky">
                                <div class="card-body p-0">
                                    <!--begin::UserLoading-->
                                    <div class="d-flex align-items-center justify-content-center py-5 px-5" v-if="userDetailsLoading">
                                        <div class="d-flex justify-content-center">
                                            <div class="spinner-border" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end::User-->

                                    <!--begin::User-->
                                    <div class="d-flex align-items-center justify-content-center py-5 px-5" v-else>
                                        <div class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                                            <div class="symbol-label bg-transparent">
                                                <i :class="[user.type === 10 ? 'la la-user fa-5x' : 'la la-university fa-5x']"></i>
                                            </div>
                                        </div>
                                        <div>
                                            <a href="javascript:;" class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{{ userFullName }}</a>
                                            <div class="text-muted">
                                                <span 
                                                    class="badge"
                                                    :class="[
                                                        user.type === 10 && 'badge-primary',
                                                        user.type === 20 && 'badge-dark',
                                                        user.type === 30 && 'badge-warning',
                                                        user.type === 60 && 'badge-info',
                                                        !user.type || ![10,20,30,60].includes(user.type) && 'badge-purple'
                                                    ]"
                                                >
                                                    {{ user.type ? $t(`user_details.user_types.${user.type}`) : '-' }}
                                                </span>
                                            </div>
                                            <div class="text-muted mt-2">
                                                <span
                                                    class="badge"
                                                    :class="[
                                                        user.level === 10 && 'badge-light',
                                                        user.level === 20 && 'badge-primary',
                                                        user.level === 30 && 'badge-success',
                                                        user.level === 40 && 'badge-dark',
                                                        !user.level || ![10,20,30,40].includes(user.level) && 'badge-purple'
                                                    ]"
                                                >
                                                    {{ user.level ? $t(`user_details.level_types.${user.level}`) : '-' }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end::User-->

                                    <!--begin::Contact Skeleton-->
                                    <div class="pb-9 pt-4 px-5" v-if="userDetailsLoading">
                                        <div class="row">
                                            <div class="col-12 col-md-6">
                                                <label class="d-block"><Skeleton :count="4" height="20px" /></label>
                                            </div>
                                            <div class="col-12 col-md-6 text-right">
                                                <p><Skeleton :count="4" height="20px" /></p>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end::Contact-->
                                    <!--begin::Contact-->
                                    <div class="pb-9 pt-4 px-5" v-else>
                                        <div class="d-flex align-items-center justify-content-between mb-2">
                                            <span class="font-weight-bold mr-2 text-truncate" :title="$t('commons.e_mail')">{{ $t('commons.e_mail') }}:</span>
                                            <a :href="`mailto:${user.email}`" class="text-muted text-truncate hover-text-primary"  :title="user.email">{{ user.email }}</a>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-between mb-2">
                                            <span class="font-weight-bold mr-2 text-truncate" :title="$t('commons.mobile_phone')">{{ $t('commons.mobile_phone') }}:</span>
                                            <a :href="`tel+:${user.email}`" class="text-muted text-truncate hover-text-primary" :title="user.phone">{{ user.phone }}</a>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-between mb-2">
                                            <span class="font-weight-bold mr-2">{{ $t('user_details.account_state') }}:</span>
                                            <span 
                                                class="badge" 
                                                :class="[
                                                    user.status === 10 && 'badge-success',
                                                    user.status === 20 && 'badge-warning',
                                                    user.status === 30 && 'badge-light',
                                                    user.status === 40 && 'badge-danger',
                                                ]"
                                            >
                                                {{ user.status ? $t(`user_details.status_types.${user.status}`) : '-' }}
                                            </span>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-between">
                                            <span class="font-weight-bold mr-2">{{ $t('user_details.last_activity') }}:</span>
                                            <span class="text-muted">{{ lastActivityAt }}</span>
                                        </div>
                                    </div>
                                    <!--end::Contact-->
                                    <!--begin::Tabs-->
                                    <ul class="navi navi-bold navi-hover pb-5" role="tablist">
                                        <li class="navi-item">
                                            <router-link :to="`/users`" data-toggle="tab" class="navi-link">
                                                <span class="navi-icon mr-2">
                                                    <span class="svg-icon svg-icon-dark">
                                                        <!--begin::Svg Icon-->
                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <polygon points="0 0 24 0 24 24 0 24"/>
                                                                <path d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000003, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-12.000003, -11.999999) "/>
                                                            </g>
                                                        </svg>
                                                        <!--end::Svg Icon-->
                                                        </span>
                                                </span>
                                                <span class="navi-text font-size-lg">{{ $t('c_management.all_customer') }}</span>
                                            </router-link>
                                        </li>
                                        <li class="navi-item" v-if="checkPermission('crm.users.show')">
                                            <router-link :to="`/users/${$route.params.id}/overview`" data-toggle="tab" :class="{'active': isOverviewTabActive}" class="navi-link">
                                                <span class="navi-icon mr-2">
                                                    <span class="svg-icon">
                                                        <!--begin::Svg Icon-->
                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <polygon points="0 0 24 0 24 24 0 24" />
                                                                <path d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z" fill="#000000" fill-rule="nonzero" />
                                                                <path d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z" fill="#000000" opacity="0.3" />
                                                            </g>
                                                        </svg>
                                                        <!--end::Svg Icon-->
                                                    </span>
                                                </span>
                                                <span class="navi-text font-size-lg">{{ $t('employees.overview') }}</span>
                                            </router-link>
                                        </li>
                                        <li class="navi-item" v-if="checkPermission('crm.users.show.externalBalances')">
                                            <router-link :to="`/users/${$route.params.id}/external-balance`" data-toggle="tab" :class="{'active': isExternalBalanceTabActive}" class="navi-link py-4">
                                                <span class="navi-icon mr-2">
                                                    <span class="svg-icon">
                                                        <!--begin::Svg Icon-->
                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24"/>
                                                                <path d="M4,7 L20,7 L20,19.5 C20,20.3284271 19.3284271,21 18.5,21 L5.5,21 C4.67157288,21 4,20.3284271 4,19.5 L4,7 Z M10,10 C9.44771525,10 9,10.4477153 9,11 C9,11.5522847 9.44771525,12 10,12 L14,12 C14.5522847,12 15,11.5522847 15,11 C15,10.4477153 14.5522847,10 14,10 L10,10 Z" fill="#000000"/>
                                                                <rect fill="#000000" opacity="0.3" x="2" y="3" width="20" height="4" rx="1"/>
                                                            </g>
                                                        </svg>
                                                        <!--end::Svg Icon-->
                                                    </span>
                                                </span>
                                                <span class="navi-text font-size-lg">{{ $t('user_details.external_balance') }}</span>
                                            </router-link>
                                        </li>
                                        <li class="navi-item" v-if="checkPermission('crm.users.show.attachments')">
                                            <router-link :to="`/users/${$route.params.id}/file-management`" data-toggle="tab" :class="{'active': isAttachmentsTabActive}" class="navi-link py-4">
                                                <span class="navi-icon mr-2">
                                                    <span class="svg-icon">
                                                        <!--begin::Svg Icon-->
                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <polygon points="0 0 24 0 24 24 0 24"/>
                                                                <path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                                                                <rect fill="#000000" x="6" y="11" width="9" height="2" rx="1"/>
                                                                <rect fill="#000000" x="6" y="15" width="5" height="2" rx="1"/>
                                                            </g>
                                                        </svg>
                                                        <!--end::Svg Icon-->
                                                    </span>
                                                </span>
                                                <span class="navi-text font-size-lg">{{ $t('user_details.file_management') }}</span>
                                            </router-link>
                                        </li>
                                        <li class="navi-item" v-if="checkPermission('crm.users.show.notifications')">
                                            <router-link :to="`/users/${$route.params.id}/notification-options`" data-toggle="tab" :class="{'active': isNotificationsTabActive}" class="navi-link py-4">
                                                <span class="navi-icon mr-2">
                                                    <span class="svg-icon">
                                                        <!--begin::Svg Icon-->
                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <path d="M17,12 L18.5,12 C19.3284271,12 20,12.6715729 20,13.5 C20,14.3284271 19.3284271,15 18.5,15 L5.5,15 C4.67157288,15 4,14.3284271 4,13.5 C4,12.6715729 4.67157288,12 5.5,12 L7,12 L7.5582739,6.97553494 C7.80974924,4.71225688 9.72279394,3 12,3 C14.2772061,3 16.1902508,4.71225688 16.4417261,6.97553494 L17,12 Z" fill="#000000"/>
                                                                <rect fill="#000000" opacity="0.3" x="10" y="16" width="4" height="4" rx="2"/>
                                                            </g>
                                                        </svg>
                                                        <!--end::Svg Icon-->
                                                    </span>
                                                </span>
                                                <span class="navi-text font-size-lg">{{ $t('user_details.notification_options') }}</span>
                                            </router-link>
                                        </li>
                                        <li class="navi-item" v-if="checkPermission('crm.users.show.transactions')">
                                            <router-link :to="{ path: '/finance/deposit', query: { user: $route.params.id } }" class="navi-link py-4">
                                                <span class="navi-icon mr-2">
                                                    <span class="svg-icon">
                                                        <!--begin::Svg Icon-->
                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24"/>
                                                                <path d="M6,9 L6,15 C6,16.6568542 7.34314575,18 9,18 L15,18 L15,18.8181818 C15,20.2324881 14.2324881,21 12.8181818,21 L5.18181818,21 C3.76751186,21 3,20.2324881 3,18.8181818 L3,11.1818182 C3,9.76751186 3.76751186,9 5.18181818,9 L6,9 Z M17,16 L17,10 C17,8.34314575 15.6568542,7 14,7 L8,7 L8,6.18181818 C8,4.76751186 8.76751186,4 10.1818182,4 L17.8181818,4 C19.2324881,4 20,4.76751186 20,6.18181818 L20,13.8181818 C20,15.2324881 19.2324881,16 17.8181818,16 L17,16 Z" fill="#000000" fill-rule="nonzero"/>
                                                                <path d="M9.27272727,9 L13.7272727,9 C14.5522847,9 15,9.44771525 15,10.2727273 L15,14.7272727 C15,15.5522847 14.5522847,16 13.7272727,16 L9.27272727,16 C8.44771525,16 8,15.5522847 8,14.7272727 L8,10.2727273 C8,9.44771525 8.44771525,9 9.27272727,9 Z" fill="#000000" opacity="0.3"/>
                                                            </g>
                                                        </svg>
                                                        <!--end::Svg Icon-->
                                                    </span>
                                                </span>
                                                <span class="navi-text font-size-lg">{{ $t('user_details.investment_transactions') }}</span>
                                            </router-link>
                                        </li>
                                        <li class="navi-item" v-if="checkPermission('crm.users.show.transactions')">
                                            <router-link :to="{ path: '/finance/withdraw', query: { user: $route.params.id } }" class="navi-link py-4">
                                                <span class="navi-icon mr-2">
                                                    <span class="svg-icon">
                                                        <!--begin::Svg Icon-->
                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24"/>
                                                                <path d="M10,6 L14.5,6 C16.709139,6 18,7.290861 18,9.5 L18,14 L16,14 L16,9.5 C16,8.3954305 15.6045695,8 14.5,8 L10,8 L10,6 Z M8,6 L8,8 L4,8 C3.44771525,8 3,7.55228475 3,7 C3,6.44771525 3.44771525,6 4,6 L8,6 Z M18,16 L18,20 C18,20.5522847 17.5522847,21 17,21 C16.4477153,21 16,20.5522847 16,20 L16,16 L18,16 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                                                                <path d="M7,5 C6.44771525,5 6,4.55228475 6,4 C6,3.44771525 6.44771525,3 7,3 L17.5,3 C19.709139,3 21,4.290861 21,6.5 L21,17 C21,17.5522847 20.5522847,18 20,18 C19.4477153,18 19,17.5522847 19,17 L19,6.5 C19,5.3954305 18.6045695,5 17.5,5 L7,5 Z" fill="#000000" fill-rule="nonzero" transform="translate(13.500000, 10.500000) rotate(-180.000000) translate(-13.500000, -10.500000) "/>
                                                            </g>
                                                        </svg>
                                                        <!--end::Svg Icon-->
                                                    </span>
                                                </span>
                                                <span class="navi-text font-size-lg">{{ $t('user_details.withdraw_transactions') }}</span>
                                            </router-link>
                                        </li>
                                        <li class="navi-item" v-if="checkPermission('crm.users.show.transactions')">
                                            <router-link :to="`/users/${$route.params.id}/financial-transaction-history`" :class="{'active': isFinancialTransactionHistoryTabActive}" class="navi-link py-4">
                                                <span class="navi-icon mr-2">
                                                    <span class="svg-icon">
                                                        <!--begin::Svg Icon-->
                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24"/>
                                                                <path d="M13,9 L13,6 C9.04563815,7.48814977 6.78867438,8.99350441 5,13 L8,13 C8.7428521,12.2 9.98856336,10 13,9 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                                                                <circle fill="#000000" cx="18" cy="7.5" r="3"/>
                                                                <circle fill="#000000" cx="6" cy="18" r="3"/>
                                                            </g>
                                                        </svg>
                                                        <!--end::Svg Icon-->
                                                    </span>
                                                </span>
                                                <span class="navi-text font-size-lg">{{ $t('user_details.financial_transaction_history') }}</span>
                                            </router-link>
                                        </li>
                                        <li class="navi-item" v-if="checkPermission('crm.users.show.history')">
                                            <router-link :to="`/users/${$route.params.id}/transaction-history`" data-toggle="tab" :class="{'active': isTransactionHistoryTabActive}" class="navi-link py-4">
                                                <span class="navi-icon mr-2">
                                                    <span class="svg-icon">
                                                        <!--begin::Svg Icon-->
                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24" />
                                                                <rect fill="#000000" x="4" y="5" width="16" height="3" rx="1.5" />
                                                                <path d="M5.5,15 L18.5,15 C19.3284271,15 20,15.6715729 20,16.5 C20,17.3284271 19.3284271,18 18.5,18 L5.5,18 C4.67157288,18 4,17.3284271 4,16.5 C4,15.6715729 4.67157288,15 5.5,15 Z M5.5,10 L12.5,10 C13.3284271,10 14,10.6715729 14,11.5 C14,12.3284271 13.3284271,13 12.5,13 L5.5,13 C4.67157288,13 4,12.3284271 4,11.5 C4,10.6715729 4.67157288,10 5.5,10 Z" fill="#000000" opacity="0.3" />
                                                            </g>
                                                        </svg>
                                                        <!--end::Svg Icon-->
                                                    </span>
                                                </span>
                                                <span class="navi-text">{{ $t('user_details.transaction_history') }}</span>
                                            </router-link>
                                        </li>
                                        <li class="navi-item" v-if="checkPermission('crm.users.show.history_agent')">
                                            <router-link :to="`/users/${$route.params.id}/agent-transaction-history`" data-toggle="tab" :class="{'active': isAgentTransactionHistoryTabActive}" class="navi-link py-4">
                                                <span class="navi-icon mr-2">
                                                    <span class="svg-icon">
                                                        <!--begin::Svg Icon-->
                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24"/>
                                                                <path d="M2,11.8650466 L2,6 C2,4.34314575 3.34314575,3 5,3 L19,3 C20.6568542,3 22,4.34314575 22,6 L22,15 C22,15.0032706 21.9999948,15.0065399 21.9999843,15.009808 L22.0249378,15 L22.0249378,19.5857864 C22.0249378,20.1380712 21.5772226,20.5857864 21.0249378,20.5857864 C20.7597213,20.5857864 20.5053674,20.4804296 20.317831,20.2928932 L18.0249378,18 L12.9835977,18 C12.7263047,14.0909841 9.47412135,11 5.5,11 C4.23590829,11 3.04485894,11.3127315 2,11.8650466 Z M6,7 C5.44771525,7 5,7.44771525 5,8 C5,8.55228475 5.44771525,9 6,9 L15,9 C15.5522847,9 16,8.55228475 16,8 C16,7.44771525 15.5522847,7 15,7 L6,7 Z" fill="#000000"/>
                                                                <path d="M6,14 C6.55228475,14 7,14.4477153 7,15 L7,17 C7,17.5522847 6.55228475,18 6,18 C5.44771525,18 5,17.5522847 5,17 L5,15 C5,14.4477153 5.44771525,14 6,14 Z M6,21 C5.44771525,21 5,20.5522847 5,20 C5,19.4477153 5.44771525,19 6,19 C6.55228475,19 7,19.4477153 7,20 C7,20.5522847 6.55228475,21 6,21 Z" fill="#000000" opacity="0.3"/>
                                                            </g>
                                                        </svg>
                                                        <!--end::Svg Icon-->
                                                    </span>
                                                </span>
                                                <span class="navi-text">{{ $t('user_details.agent_transaction_history') }}</span>
                                            </router-link>
                                        </li>
                                    </ul>
                                    <!--end::Tabs-->
                                </div>
                            </div>
                        </div>
                        <!--end::aside -->
                        <!--begin::content -->
                        <div class="col-lg-8 col-xl-9">
                            <router-view />
                        </div>
                        <!--end::content -->
                    </div>
                    <!--end::sticky -->
                    
                </div>
                <!--end::Container-->
            </div>
            <!--end::Entry-->
        </template>
    </div>
</template>

<script>
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import { mapState } from 'vuex';
import moment from 'moment';
import {Skeleton} from 'vue-loading-skeleton';
import i18n from '@/config/_i18n'

export default {
  name: 'Details',
  data() {
    return {
      activeTab: "overview",
    }
  },
  components: {
    Breadcrumb,
    Skeleton
  },
  computed: {
    ...mapState({
      userDetailsLoading: (state) => state.userdetails.userDetailsLoading,
      user: (state) => state.userdetails.userDetails.user,
      markets: (state) => state.userdetails.userDetails.markets,
      permissions: (state) => state.customer.permissions,
    }),
    userFullName() {
        let fullName = `${this.user.name} ${this.user.surname}`;
        const companyName = this.user?.company;
        if(this.user?.type && `${this.user.type}` === "20") {
            return companyName || fullName;
        }
        return fullName;
    },
    lastActivityAt() {
        if(this.user?.last_activity_at) {
            return moment(this.user.last_activity_at).locale(i18n.locale).fromNow();
        }
        return '';
    },
    breadcrumb_data() {
        return { 
            title: this.$t('user_details.customers_information'),
            subtitle: [ `#${this.$route.params.id} - ${this.userFullName}` ]
        };
    },
    isOverviewTabActive() {
        return this.isRouteActive(['user_overview', 'edit_user_info'])
    },
    isExternalBalanceTabActive() {
        return this.isRouteActive(['user_external_balance', 'user_add_external_balance', 'user_edit_external_balance'])
    },
    isAttachmentsTabActive() {
        return this.isRouteActive(['user_file_management'])
    },
    isNotificationsTabActive() {
        return this.isRouteActive(['user_notification_options'])
    },
    isFinancialTransactionHistoryTabActive() {
        return this.isRouteActive(['user_financial_transaction_history'])
    },
    isTransactionHistoryTabActive() {
        return this.isRouteActive(['user_transaction_history'])
    },
    isAgentTransactionHistoryTabActive() {
        return this.isRouteActive(['user_agent_transaction_history'])
    }
  },
  methods: {
    getUserDetails(isLoading = false) {
        this.$store.dispatch('userdetails/getUserDetailsAction', { id: this.$route.params.id, isLoading })
    },
    checkPermission(permission) {
      return this.permissions?.some(val => String(val.name) === String(permission));
    },
    isRouteActive(routes) {
        return routes.includes(this.$route.name)
    }
  },
  created() {
    if(this.permissions?.length > 0 && !this.checkPermission('crm.users.show')) {
      this.$router.push('/no-permission')
    }
    else this.getUserDetails(true)
  },
  watch: {
      $route: function(val) {
        const loading = val?.name === "user_overview" ? true : false;
        this.getUserDetails(loading);          
      }
  }
  
}
</script>
<style scoped>
 .hover-text-primary:hover {
     color: #3699FF !important;
 }
 .badge-purple {
    color: #ffffff;
    background-color: #642583;
  }
</style>
